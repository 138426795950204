<template>
  <v-app class="grey">
    <v-layout>
      <AppHeader/>
      <v-main>
        <v-container>
          <v-row>
            <v-col>
              <v-card>
                <v-card-title class="bg-primary"><h4>{{ $t('welcomeCard.title') }}</h4></v-card-title>
                <v-card-text>
                  <section class="mt-3"><span v-html="$t('welcomeCard.firstSection')"/></section>
                  <section class="mt-3">{{ $t('welcomeCard.secondSection') }}</section>
                  <section class="mt-3">
                    <span v-html="$t('welcomeCard.thirdSection')"/>
                    <ul class="mt-3">
                      <li class="d-flex align-center">
                        <img width="30" class="mr-3"
                             src="https://storage.googleapis.com/text-to-speech-online-statics/podcast.png"/>
                        <div>{{ $t('welcomeCard.firstUseCase') }}</div>
                      </li>
                      <li class="d-flex align-center mt-3">
                        <img width="30" class="mr-3"
                             src="https://storage.googleapis.com/text-to-speech-online-statics/youtube.png"/>
                        <div>{{ $t('welcomeCard.secondUseCase') }}</div>
                      </li>
                      <li class="d-flex align-center mt-3">
                        <img width="30" class="mr-3"
                             src="https://storage.googleapis.com/text-to-speech-online-statics/book.png"/>
                        <div>{{ $t('welcomeCard.thirdUseCase') }}</div>
                      </li>
                      <li class="d-flex align-center mt-3">
                        <img width="30" class="mr-3"
                             src="https://storage.googleapis.com/text-to-speech-online-statics/star.png"/>
                        <div>{{ $t('welcomeCard.fourthUseCase') }}</div>
                      </li>
                    </ul>
                  </section>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-card>
                <v-card-title class="bg-primary"><h4>{{ $t('howItWorksCard.title') }}</h4></v-card-title>
                <v-card-text class="d-flex">
                  <v-timeline class="mt-3" side="end" justify="left">
                    <v-timeline-item :icon-color="'surface'" :dot-color="'primary'"
                                     :icon="'mdi-text-box-edit-outline'">
                      {{ $t('howItWorksCard.firstStep') }}
                    </v-timeline-item>
                    <v-timeline-item :icon-color="'surface'" :dot-color="'primary'" :icon="'mdi-button-cursor'">
                      {{ $t('howItWorksCard.secondStep') }}
                    </v-timeline-item>
                    <v-timeline-item :icon-color="'surface'" :dot-color="'primary'" :icon="'mdi-timer-sand'">
                      {{ $t('howItWorksCard.thirdStep') }}
                    </v-timeline-item>
                    <v-timeline-item :icon-color="'surface'" :dot-color="'primary'" :icon="'mdi-account-voice'">
                      {{ $t('howItWorksCard.fourthStep') }}
                    </v-timeline-item>
                  </v-timeline>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-card>
                <v-card-title class="bg-primary"><h4>{{ $t('textConversionCard.title') }}</h4></v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col>
                        <v-textarea v-model="text"
                                    :label="$t('textConversionCard.textAreaLabel')"
                                    :rules="[rules.isLengthOk]"/>
                      </v-col>
                      <v-col>
                        <v-select
                            v-model="selectedSpeechLanguage"
                            :items="speechLanguages"
                            :label="$t('textConversionCard.languageSelectTitle')"
                        >
                          <template #selection="{item}">
                            <v-list-item>
                              <div class="d-flex align-center">
                                <img alt="flag" height="15" class="mr-3" :src=item.raw.iconUrl>
                                <span>{{ $t(item.title) }}</span>
                              </div>
                            </v-list-item>
                          </template>
                          <template #item="{item, props}">
                            <v-list-item style="cursor: pointer" v-bind="props" :prepend-avatar="item.raw.iconUrl" :title="$t(item.title)"/>
                          </template>
                        </v-select>
                        <v-select
                            v-model="selectedSpeechVoice"
                            :items="currentSpeechVoices"
                            :label="$t('textConversionCard.voiceSelectTitle')">
                          <template #selection="{item}">
                            <v-list-item>
                              <div class="d-flex align-center">
                                <v-icon class="mr-3" :icon="item.raw.iconName"/>
                                <span>{{ item.title }}</span>
                              </div>
                            </v-list-item>
                          </template>
                          <template #item="{item, props}">
                            <v-list-item style="cursor: pointer" v-bind="props" :prepend-icon="item.raw.iconName"/>
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-btn class="bg-primary" :disabled="!isLengthOk || converting" @click="convertT2s">
                    {{ $t('textConversionCard.button') }}
                  </v-btn>
                  <v-progress-circular class="ml-3" indeterminate v-if="converting"/>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-main>
    </v-layout>
  </v-app>
</template>

<script>
import {convertt2s} from '@/api/t2sconverter'
import AppHeader from '@/components/Header.vue'

export default {
  name: 'App',
  components: {AppHeader},
  data() {
    return {
      MAX_TEXT_LENGTH: 300,
      text: "",
      rules: {
        isLengthOk: text => text.length <= this.MAX_TEXT_LENGTH || 'La longitud máxima del texto es de 300 caracteres. '
      },
      converting: false,
      speechLanguages: [
        {
          iconUrl: 'https://storage.googleapis.com/text-to-speech-online-statics/spanish.png',
          title: 'speechVoices.spanishSpain.title',
          value: 'spanishSpain'
        },
        {
          iconUrl: 'https://storage.googleapis.com/text-to-speech-online-statics/eeuu.png',
          title: 'speechVoices.spanishUS.title',
          value: 'spanishUS'
        },
        {
          iconUrl: 'https://storage.googleapis.com/text-to-speech-online-statics/english.png',
          title: 'speechVoices.englishUK.title',
          value: 'englishUK'
        },
        {
          iconUrl: 'https://storage.googleapis.com/text-to-speech-online-statics/eeuu.png',
          title: 'speechVoices.englishUS.title',
          value: 'englishUS'
        }
      ],
      speechVoices: [
        {
          iconName: 'mdi-face-woman',
          title: 'Rita',
          language: 'spanishSpain',
          value: 'spanish_spain_standard_a'
        },
        {
          iconName: 'mdi-face-man',
          title: 'Valentín',
          language: 'spanishSpain',
          value: 'spanish_spain_standard_b'
        },
        {
          iconName: 'mdi-face-woman',
          title: 'Elvira',
          language: 'spanishSpain',
          value: 'spanish_spain_standard_c'
        },
        {
          iconName: 'mdi-face-woman',
          title: 'Estrella',
          language: 'spanishSpain',
          value: 'spanish_spain_standard_d'
        },
        {
          iconName: 'mdi-face-woman',
          title: 'Paquita',
          language: 'spanishUS',
          value: 'spanish_us_standard_a'
        },
        {
          iconName: 'mdi-face-man',
          title: 'Joaquín',
          language: 'spanishUS',
          value: 'spanish_us_standard_b'
        },
        {
          iconName: 'mdi-face-man',
          title: 'Hernán',
          language: 'spanishUS',
          value: 'spanish_us_standard_c'
        },
        {
          iconName: 'mdi-face-woman',
          title: 'Scarlett',
          language: 'englishUK',
          value: 'english_uk_standard_a'
        },
        {
          iconName: 'mdi-face-man',
          title: 'Nelson',
          language: 'englishUK',
          value: 'english_uk_standard_b'
        },
        {
          iconName: 'mdi-face-woman',
          title: 'Samantha',
          language: 'englishUK',
          value: 'english_uk_standard_c'
        },
        {
          iconName: 'mdi-face-man',
          title: 'Jasper',
          language: 'englishUK',
          value: 'english_uk_standard_d'
        },
        {
          iconName: 'mdi-face-woman',
          title: 'Jeniffer',
          language: 'englishUK',
          value: 'english_uk_standard_f'
        },
        {
          iconName: 'mdi-face-man',
          title: 'Walter',
          language: 'englishUS',
          value: 'english_us_standard_a'
        },
        {
          iconName: 'mdi-face-man',
          title: 'Jackson',
          language: 'englishUS',
          value: 'english_us_standard_b'
        },
        {
          iconName: 'mdi-face-woman',
          title: 'Ellen',
          language: 'englishUS',
          value: 'english_us_standard_c'
        },
        {
          iconName: 'mdi-face-man',
          title: 'Oscar',
          language: 'englishUS',
          value: 'english_us_standard_d'
        },
        {
          iconName: 'mdi-face-woman',
          title: 'Angela',
          language: 'englishUS',
          value: 'english_us_standard_e'
        },
        {
          iconName: 'mdi-face-woman',
          title: 'Kayla',
          language: 'englishUS',
          value: 'english_us_standard_f'
        },
        {
          iconName: 'mdi-face-woman',
          title: 'Kelsey',
          language: 'englishUS',
          value: 'english_us_standard_g'
        },
        {
          iconName: 'mdi-face-woman',
          title: 'Joy',
          language: 'englishUS',
          value: 'english_us_standard_h'
        },
        {
          iconName: 'mdi-face-man',
          title: 'Douglas',
          language: 'englishUS',
          value: 'english_us_standard_i'
        },
        {
          iconName: 'mdi-face-man',
          title: 'Leo',
          language: 'englishUS',
          value: 'english_us_standard_j'
        }
      ],
      selectedSpeechLanguage: 'spanishSpain',
      selectedSpeechVoice: 'spanish_spain_standard_a'
    }
  },
  methods: {
    async convertT2s() {
      this.converting = true
      const blob = new Blob([await convertt2s(this.text, this.selectedSpeechVoice)], {type: 'audio/mpeg'})
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = 'audio-file'
      link.click()
      URL.revokeObjectURL(link.href)
      this.converting = false
    }
  },
  watch: {
    selectedSpeechLanguage(newSelectedSpeechLanguage){
      this.selectedSpeechVoice = this.speechVoices.filter(speechVoice => speechVoice.language === newSelectedSpeechLanguage)[0].value
    }
  },
  computed: {
    isLengthOk() {
      return this.text.length <= this.MAX_TEXT_LENGTH
    },
    currentSpeechVoices() {
      return this.speechVoices.filter(speechVoice => speechVoice.language === this.selectedSpeechLanguage)
    },
  }
}
</script>

<style>
</style>
