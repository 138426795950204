export const messages = {
    es: {
        welcomeCard: {
            title: '¡Hola!',
            firstSection: '<b>Text to Audio</b> es una aplicación <b>gratuita</b> para convertir texto a voz.',
            secondSection: 'El resultado de la conversión se descargará en tu dispositivo como un fichero de audio mp3 y podrá usarlo tantas veces como quieras.',
            thirdSection: '<p>Puedes utilizar <b>Text to Audio</b> para:</p>',
            firstUseCase: 'Dictar tus podcasts y ahorrarte el esfuerzo de gabarlos tú mismo.',
            secondUseCase: 'Crear pistas de audio para el contenido que vayas a subir a tus redes sociles.',
            thirdUseCase: 'Leer en alto apuntes, libros, artículos y cualquier tipo de texto.',
            fourthUseCase: '¡Y mucho más!'
        },
        howItWorksCard: {
            title: '¿Cómo funciona?',
            firstStep: 'Añade el texto que quieras convertir a voz en la caja de abajo.',
            secondStep: 'Pulsa el para iniciar la conversión del texto a voz.',
            thirdStep: 'Espera unos segundos mientras se procesa tu texto.',
            fourthStep: 'Descarga el fichero de audio a tu ordenador.'
        },
        textConversionCard: {
            title: 'Añade aquí tu texto',
            textAreaLabel: 'Tu texto',
            button: 'Convertir',
            languageSelectTitle: 'Idioma',
            voiceSelectTitle: 'Tipo de voz',
        },
        speechVoices: {
            spanishSpain: {
                title: 'Español (España)'
            },
            spanishUS: {
                title: 'Español (EEUU)'
            },
            englishUK: {
                title: 'Inglés (UK)'
            },
            englishUS: {
                title: 'Inglés (EEUU)'
            },
        }
    },
    en: {
        welcomeCard: {
            title: 'Hi!',
            firstSection: '<b>Text to Audio</b> is a <b>free</b> application for converting text to speech.',
            secondSection: 'The result of the conversion will be downloaded to your device as an mp3 audio file and you can use it as many times as you want.',
            thirdSection: '<p>You can use <b>Text to Audio</b> for:</p>',
            firstUseCase: 'Dictate your podcasts and save you the effort of recording them yourself.',
            secondUseCase: 'Create audio tracks for the content you are going to upload to your social networks.',
            thirdUseCase: 'Read aloud notes, books, articles and any type of text.',
            fourthUseCase: 'And much more!'
        },
        howItWorksCard: {
            title: 'How does it work?',
            firstStep: 'Add the text you want to convert to speech in the box below.',
            secondStep: 'Press the to start the text-to-speech conversion.',
            thirdStep: 'Wait a few seconds while your text is being processed.',
            fourthStep: 'Download the audio file to your computer.'
        },
        textConversionCard: {
            title: 'Add your text here',
            textAreaLabel: 'Your text',
            button: 'Convert',
            languageSelectTitle: 'Language',
            voiceSelectTitle: 'Voice type',
        },
        speechVoices: {
            spanishSpain: {
                title: 'Spanish (Spain)'
            },
            spanishUS: {
                title: 'Spanish (US)'
            },
            englishUK: {
                title: 'English (UK)'
            },
            englishUS: {
                title: 'English (US)'
            },
        }
    }
}