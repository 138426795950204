<template>
  <v-app-bar :elevation="2">
    <v-app-bar-title>
      <div class="d-flex align-center">
        <img height="50" class="mr-3" src="https://storage.googleapis.com/text-to-speech-online-statics/main.ico"/>
        <h3>Text to Audio</h3>
      </div>
    </v-app-bar-title>
    <v-spacer/>
    <!-- LANGUAGE SELECTOR -->
    <v-btn class="text-capitalize">
      <img height="15" class="mr-3" :src=selectedLanguage.iconUrl>
      <span>{{selectedLanguage.name}}</span>
      <v-menu activator="parent">
        <v-list>
          <v-list-item v-for="(item, index) in languages" :key="index" :value="index">
            <v-list-item-title @click="selectLanguage(item)">
              <img height="15" class="mr-3"
                   :src=item.iconUrl>
              <span>{{item.name}}</span>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-btn>
  </v-app-bar>
</template>

<script>
export default {
  name: "AppHeader",
  data() {
    return {
      languages: [
        {
          name: "Español",
          iconUrl: "https://storage.googleapis.com/text-to-speech-online-statics/spanish.png",
          localeToken: "es"
        },
        {
          name: "English",
          iconUrl: "https://storage.googleapis.com/text-to-speech-online-statics/english.png",
          localeToken: "en"
        }
      ],
      selectedLanguage: {name: "Español", iconUrl: "https://storage.googleapis.com/text-to-speech-online-statics/spanish.png"}
    }
  },
  methods: {
    selectLanguage(language){
      this.$i18n.locale = language.localeToken
      this.selectedLanguage = language
    }
  }
}
</script>

<style scoped>

</style>