import {createApp} from 'vue'
import {createI18n} from 'vue-i18n'
import App from './App.vue'
import 'vuetify/styles'
import {createVuetify} from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import '@mdi/font/css/materialdesignicons.css'
import {messages} from '@/i18n/messages'


const myCustomLightTheme = {
    dark: false,
    colors: {
        background: '#AAE3E2',
        surface: '#FFFFFF',
        primary: '#D9ACF5',
        'primary-darken-1': '#3700B3',
        secondary: '#03DAC6',
        'secondary-darken-1': '#018786',
        error: '#B00020',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FB8C00',
    }
}

const vuetify = createVuetify({
    components,
    directives,
    theme: {
        defaultTheme: 'myCustomLightTheme',
        themes: {
            myCustomLightTheme,
        }
    },
    icons: {
        iconfont: 'mdi',
    }
})

const i18n = createI18n({
    locale: 'es',
    fallbackLocale: 'en',
    messages
})

createApp(App).use(i18n).use(vuetify).mount('#app')
